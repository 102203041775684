<template>
  <v-dialog persistent v-model="open" width="35%" attach="#main-container">
    <v-card class="br-6">
      <v-card-text class="pa-4 pb-6">
        <v-row>
          <v-col class="d-flex w-100 justify-space-between align-center">
            <span class="h5 semiBold">Editar email del cliente</span>
            <v-btn icon small @click="toggle">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-form ref="emailForm">
              <LabelComponent text="Email*" />
              <inputText
                placeholder="Email"
                :model="correo"
                color="#0077C8"
                @input="correo = $event"
                :rules="[rules.required, rules.email]"
                :hide="false"
                name="EditEmailModal"
              />
            </v-form>
          </v-col>
          <v-col>
            <Alert variant="warning">
              <template slot="content">
                <span>
                  Importante: <br />
                  El nuevo email ingresado será el que aparecerá en la póliza y
                  se actualizará en los datos del cliente.
                </span>
              </template>
            </Alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex justify-space-around">
              <v-btn
                rounded
                outlined
                color="primary "
                width="130"
                class="text-none"
                @click="toggle"
                >Cancelar</v-btn
              >
              <v-btn
                rounded
                color="primary"
                width="196"
                class="text-none"
                @click="handlerSetNewEmail"
                :loading="loading"
                >Guardar email</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import inputText from "@/components/Inputs/Input";
import Alert from "@/components/Alert/Alert.vue";
import LabelComponent from "@/components/Inputs/Label.vue";
import { validarEmail } from "@/store/resources/validate.js";
import { mapActions } from "vuex";
export default {
  props: ["toggle", "clientEmail", "ProposalNumber"],
  data() {
    return {
      open: true,
      correo: "",
      loading: false,
      rules: {
        required: (value) => !!value || "Campo requerido.",
        email: (value) => validarEmail(value) || "Correo invalido.",
      },
    };
  },
  components: {
    LabelComponent,
    inputText,
    Alert,
  },
  mounted() {
    this.correo = this.clientEmail;
  },
  methods: {
    ...mapActions("Proposal", ["updateProposalEmail"]),
    handlerSetNewEmail() {
      const formValid = this.$refs.emailForm.validate();
      if (formValid) {
        this.loading = true;
        this.updateProposalEmail({
          id: this.ProposalNumber || "",
          email: this.correo,
        })
          .then((response) => {
            this.$emit("setDocs", response.data, response.email, false, true);
            this.correo = response.email;
            this.$store.commit("setSnackbar", {
              active: true,
              text: response.message,
              top: true,
              right: true,
              color: "success",
            });
            this.open = false;
          })
          .catch((message) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: message,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

:deep(.v-dialog) {
  overflow: hidden !important;
}
</style>
