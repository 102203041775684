<template>
  <v-dialog persistent v-model="open" width="50%" attach="#main-container">
    <v-card class="br-6" height="400px">
      <div class="d-flex align-center justify-end">
        <div @click="close" class="d-flex align-center">
          <v-icon>mdi-close</v-icon>
        </div>
      </div>

      <v-layout align-center justify-center>
        <v-flex
          xs10
          v-if="
            getItemShow.tipo_archivo === 'image' ||
            getItemShow.tipo_archivo === 'img'
          "
        >
          <v-img
            class="global-image-fit"
            :src="ImageFile"
            v-if="ImageFile !== null"
          ></v-img>
        </v-flex>
        <v-flex xs12 v-else>
          <embed width="100%" height="380px" frameborder="0" :src="ImageFile" />
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  props: ["document"],
  data() {
    return {
      open: true,
      file_type: null,
      url_pdf: "",
    };
  },
  methods: {
    ...mapActions("Payment", ["CloseShowModal"]),
    close() {
      this.CloseShowModal({ data: false });
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
  },
  computed: {
    ...mapGetters("Payment", ["getItemShow"]),
    ImageFile() {
      if (this.document) {
        if (this.document.data_archivo && this.document.data_archivo !== null) {
          return this.url_pdf;
        }
      }
      if (
        this.getItemShow.data_archivo &&
        this.getItemShow.data_archivo !== null
      ) {
        return this.url_pdf;
        //const blob = this.b64toBlob(this.getItemShow.data_archivo, ".pdf");
      }
      return URL.createObjectURL(this.getItemShow.archivo);
    },
  },
  mounted() {
    if (this.document) {
      this.file_type = this.document.tipo_archivo;
      axios
        .get(this.document?.url_achivo)
        .then((res) => {
          console.log("res", res);

          // this.url_pdf = res.data.data.url;
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      this.file_type = this.getItemShow.tipo_archivo;
      axios
        .get(this.getItemShow?.data_archivo && this.getItemShow?.url_archivo)
        .then((res) => {
          this.url_pdf = res.data.data.url;
        });
    }
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

:deep(.v-dialog) {
  overflow: hidden !important;
}
</style>
